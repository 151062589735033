export default {
	fab: {
		selfie: null,
		terms: null,
		customer_code: null,
		sales_order_status: null,
		title: null,
		nama: null,
		kota: null,
		phone: null,
		hp: null,
		npwp: null,
		fax: null,
		email: null,
		dob: null,
		kotaKelahiran: null,
		ktp: null,
		code_area: null,
		code_group_area: null,
		code_group: null,
		code_type: null,
		location: null,
		location_area: null,
		location_address: null,
		tax_address: null,
		invoice_address: null,
		lokasi_layanan: null,
		status_lokasi: null,
		lampiran_ktp: null,
		lampiran_tdp: null,
		lampiran_npwp: null,
		lampiran_akta_perusahaan: null,
		lampiran_siup: null,
		lampiran_sk_domisili: null,
		lampiran_nib: null,
		lampiran_ipl_pbb: null,
		payment_type: null,
		subscription_periode: null,
		installation_date: null,
		activation_date: null,
		selected_product: null,
		file_lampiran_ktp: null,
		file_lampiran_npwp: null,
		file_lampiran_siup: null,
		file_lampiran_akta: null,
		file_lampiran_sk: null,
		file_lampiran_ipl_pbb: null,
		zipcode: null,
		lineTelephone: null,
		signature: null,
		dob_place: null,
		pic_title: null,
		pic_name: null,
		email_secondary: null,
		notes: null,
		passport: null,
		readyness: null,
		notes_status: null,
		multiple: {},
		products_customer_view: [],
		registration_code: null,
		answer: [],
		deposit: null,
		deposit_amount: null,
		returnee: null,
		returnee_customer_code: null
	},
	sales_order: {
		id: null,
		selfie: null,
		terms: null,
		customer_code: null,
		sales_order_status: null,
		customer_title: null,
		customer_name: null,
		customer_initial_name: null,
		customer_phone_number: null,
		customer_phone_number_2: null,
		nama: null,
		kota: null,
		customer_activation_email: null,
		customer_phone: null,
		hp: null,
		customer_npwp: null,
		fax: null,
		customer_email: null,
		customer_dob: null,
		kotaKelahiran: null,
		customer_ktp: null,
		customer_code_area: null,
		code_group_area: null,
		customer_code_group: null,
		customer_code_type: null,
		customer_location: null,
		customer_location_area: null,
		customer_location_address: null,
		tax_address: null,
		invoice_address: null,
		lokasi_layanan: null,
		status_lokasi: null,
		lampiran_ktp: null,
		lampiran_tdp: null,
		lampiran_npwp: null,
		lampiran_akta_perusahaan: null,
		lampiran_siup: null,
		lampiran_sk_domisili: null,
		lampiran_nib: null,
		lampiran_ipl_pbb: null,
		customer_payment_type: null,
		customer_subscription_periode: null,
		customer_installation_date: null,
		customer_activation_date: null,
		selected_product: null,
		file_lampiran_ktp: null,
		file_lampiran_npwp: null,
		file_lampiran_siup: null,
		file_lampiran_akta: null,
		file_lampiran_sk: null,
		file_lampiran_ipl_pbb: null,
		customer_zipcode: null,
		lineTelephone: null,
		signature: null,
		dob_place: null,
		customer_pic_title: null,
		customer_pic_name: null,
		customer_email_secondary: null,
		customer_notes: null,
		customer_passport: null,
		readyness: null,
		notes_status: null,
		registration_code: null,
		customer_deposit: null,
		customer_deposit_amount: null
	},
	catalog: {
		id: null,
		name: null,
		code_group_area: null,
		code_group: null,
		code_type: null,
		location_code: null,
		location_code_area: null,
		tax: null,
	},
	catalog_detail: {
		id: null,
		selected_product: null,
		product_category: null,
		product_type: null,
		product_code: null,
		price_exclude_tax: null,
		price: null,
		flag: null,
		tax: null,
		tax_option: null,
	},
	masterDeviceType:{
		id: null,
		device_code: null,
		name: null,
		description: null,
		flag: null
	},
	masterserviceType:{
		id: null,
		service_type_code: null,
		name: null,
		description: null
	},
	masterCustomerTitle: {
		id: null,
		name: null,
		description: null
	},
	masterContractSubscriptionStatus: {
		id: null,
		name: null
	},
	masterSubscriptionTemplate: {
		id: null,
		subs_template_code: null,
		name: null,
		invoicing_period: null,
		invoicing_period_type: null,
		flag: null,
	},
	masterCustomerAddressType: {
		id: null,
		name: null
	},
	masterContract: {
		id: null,
		contract_code: null,
		customer_code: null,
		customer_name: null,
		start_date_contract: null,
		end_date_contract: null,
		contract_status: null,
		subs_template_code: null
	},
	masterContractDetail: {
		id: null,
		contract_code: null,
		customer_code: null,
		customer_name: null,
		start_date_contract: null,
		end_date_contract: null,
		contract_status: null,
		subs_template_code: null,
		code_area: null,
		code_group: null,
		code_type: null
	},
	contractStatus: {
		id: null,
		name: null,
		description: null
	},
	ContractSubscription: {
		id: null,
		code_area: null,
		code_group: null,
		code_type: null, 
		contract_code: null,
		product_code:null,
		customer_code:null,
		product_name: null,
		start_time: null,
		end_time: null,
		end_time2: null,
		price: null,
		discount_price: null,
		contract_subscription_status: null,
		selected_product: null,
		qty: null,
		price: null,
		total: null,
		subscription_type: null,
	},
	masterCustomerArea: {
		id: null,
		code_area: null,
		name: null,
		zipcode: null 
	},
	masterAccessMenu: {
		id: null,
		nik: null,
		menu: null,
		selected_menu: null,
		admin: null,
		view_only: null,
	},
	masterCustomerGroupType: {
		id: null,
		code_group_type: null,
		name: null
	},
	masterCustomerGroupArea: {
		id: null,
		code_group_area: null,
		name: null
	},
	masterCustomerType: {
		id: null,
		code_group_type: null,
		code_type: null,
		name: null
	},
	masterCustomerSubArea: {
		id: null,
		code_area: null,
		name: null,
		zipcode: null
	},
	masterLocation: {
		id: null,
		code_group_area: null,
		code_area: null,
		code_group: null,
		code_type: null,
		location_code: null,
		name: null,
		description: null
	},
	masterTnc: {
		id: null,
		tnc_bahasa: null,
		tnc_english: null
	},
	login: {
		nik: null,
		password: null
	},
	register: {
		registration_code: null,
		code_site: null,
		code_area: null,
		code_group: null,
		code_type: null,
		location: null,
		captcha: null,
		email: null,
		location_area: null,
		uuid: null,
		questioner: [],
		phone_number : null,
	},
	newregister: {
		id: null,
		fullname: null,
		email: null,
		mobile_phone: null,
		address: null,
		captcha: null,
		messages: null
	},
	customer: {
		customer_code: null,
		title: null,
		name: null,
		kota: null,
		dob: null,
		kotaKelahiran: null,
		initial_name: null,
		code_area: null,
		code_group: null,
		code_type: null,
		location: null,
		location_area: null,
		location_address: null,
		phone_number: null,
		phone_number_2: null,
		email: null,
		ktp: null,
		npwp: null,
		status: null,
	},
	contract_subscription_status:{
		id: null,
		name: null
	},
	invoice:{
		id: null,
		invoice_number: null,
		customer_code: null,
		contract_code: null,
		invoice_date: null,
		due_date: null,
		total_price_exclude_tax: null,
		total_price_include_tax: null,
		tax_name: null
	},
	invoice_period_type:{
		id: null,
		name: null,
		description: null ,
		total_days: null,
	},
	customer_address: {
		customer_code: null,
		address_type: null,
		address: null,
		status_ownership: null,
	},
	sales_order_address: {
		customer_code: null,
		address_type: null,
		address: null,
		status_ownership: null,
	},
	customer_product: {
		customer_code: null,
		product_code: null,
		selected_product: null,
		promotion_code: null,
		qty: null,
		price: null,
	},
	customer_subscription: {
		customer_code: null,
		product_code: null,
		selected_product: null,
		promotion_code: null,
		qty: null,
		price: null,
		equipment_code: null,
		category: null,
		name: null,
		mac_address: null,
		serial_number: null,
		ip_address: null,
		equipment_qty: null, 
		customer_subscribe_id: null,
		device_type: null,
		service_type: null,
		activation_date: null,
		termination_date: null,
		status: null
	},
	sales_order_subscription: {
		customer_code: null,
		product_code: null,
		selected_product: null,
		promotion_code: null,
		qty: null,
		price: null,
		equipment_code: null,
		category: null,
		name: null,
		mac_address: null,
		serial_number: null,
		ip_address: null,
		equipment_qty: null, 
		customer_subscribe_id: null,
		device_type: null,
		service_type: null,
		date_activation: null,
		date_termination: null,
		status: null

	},
	customer_equipment: {
		customer_code: null,
		equipment_code: null,
		category: null,
		name: null,
		mac_address: null,
		serial_number: null,
		ip_address: null,
		device_type: null,
		service_type: null,
		activation_date: null,
		termination_date: null,
		status: null
	},
	customer_telephone: {
		customer_code: null,
		equipment_code: null,
		phone_number: null,
		phone_number_range_1: null,
		phone_number_range_2: null,
		block_range: null
	},
	customer_attachment: {
		attachment_type: null,
		selected_file: null,
	},
	sales_order_attachment: {
		attachment_type: null,
		selected_file: null,
	},
	promotion: {
		promotion_code: null,
		name: null,
		start_date: null,
		end_date: null,
		percentage: null,
		price: null,
		promotion_type: null,
		promotion_status: null,
		code_area: null,
		code_group: null,
		code_type: null,
		location: null,
		quota: null
	},
	product: {
		product_code: null,
		code_area: null,
		code_group: null,
		code_type: null,
		name: null,
		product_category: null,
		product_type: null,
		price: null,
		flag: null,
		tax: null,
		tax_option: null,
		price_exclude_tax: null,
		multiple_qty: null,
		subscription_template_code: null
	},
	product_promotion: {
		code_group_area: null,
		code_area: null,
		code_group: null,
		code_type: null,
		product_code: null,
		selected_product: null,
		promotion_code: null
	},
	location_addrress: {
		location_address_code: null,
		location_code: null,
		location_area_code: null,
		name: null,
		address_name: null,
		address_block: null,
		address_number: null,
		description: null,
		readyness: null,
	},
	location_area: {
		location_area_code: null,
		location_code: null,
		name: null,
		description: null,
	},
	filter_product: {
		group_area: null,
		area: null,
		group: null,
		type: null,
		product_category: null,
		product_type: null,
	},
	filter_location: {
		group_area: null,
		code_area: null,
		code_group: null,
		code_type: null,
	},
	filter_customer: {
		area: null,
		group: null,
		type: null,
	},
	customer_signature: {
		id: null,
		customer_code: null,
		signature: null
	},
	sales_order_signature: {
		id: null,
		customer_code: null,
		signature: null
	},
	filter_promotion: {
		promotion_type: null,
		promotion_status: null,
	},
	sales_order_status: {
		id: null,
		name: null,
		description: null
	},
	filter_sales_order_status: {
		sales_status: null
	},
	tools_email_activation: {
		lampiran: null,
	},
	masterTax: {
		id: null,
		name: null,
		value: null
	},
	priceCatalogStatus: {
		id: null,
		name: null,
		description: null
	},
	master_menu: {
		id: null,
		title: null,
		route: null,
		icon: null,
		children: null,
		children_lv2: null,
		children_lv3: null,
	},
	filter_sales_order_chart: {
		year: null,
		month: null,
	},
	customer_logs: {
		alias: null,
		form: null,
		to: null,
	},
	sales_order_logs: {
		alias: null,
		form: null,
		to: null,
	}

}