<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <span class="brand-logo">
        <b-img :src="appLogoImage" alt="logo" class="img_logo"/>
      </span>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <!-- <b-row class="content">
          <b-col> -->
            <h1 class="mb-1">
              Response Complete
            </h1>
            <p class="mb-2">
              Please check your email to continue this step
            </p>
            <p v-if="(this.registerForm.email != null)" style="margin-top:-15px;">
              <b>Or</b><br>
              <i>If you didn't receive any email, you can check the spam folder or resend a verification email.</i><br>
              <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                @click="resendEmail()"
                style="margin-top: 5px;"
              >
              Resend verification email
            </b-button>
            </p>
            <!-- <p class="mb-2" v-if="(this.registerForm.email != null)">
              
            </p> -->

            
    
            <!-- image -->
            <b-img
              fluid
              :src="imgUrl"
              alt="Error page"
            />
          <!-- </b-col>
        </b-row> -->
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from "@themeConfig";
import axios from "axios";
import interfaces from "@/templates/_interfaces.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    ToastificationContent,
  },
  setup() {
      // App Name
      const { appName, appLogoImage } = $themeConfig.app;
      return {
        appName,
        appLogoImage,
      };
    },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      registerForm: null,
      registerForm: interfaces.register,
    }
  },
  methods: {
      init: function () {

      },
      resendEmail: function () {
        axios.post("self_registration/resend/email_activation", {email:this.registerForm.email}).then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Form Add successful",
                icon: "EditIcon",
                variant: "success",
                text: response.data.message,
              },
            });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Add Failed",
              icon: "EditIcon",
              variant: "danger",
              text: error.message,
            },
          });
        })
        .finally(() => {
          this.isLoading = false;
          // this.resetForm();
          // this.init();
        });
      },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
      this.init();
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.mb-2{
  font-size: 15px;
}
.img_logo{
  margin-top: -40px;
  margin-left: -40px;
}
.w-100{
  padding-top: 40px
}
</style>
